<template>
  <div class="header-section">
    <div
      class="drawer drawer--right drawer-close"
      :class="isHamburger? 'drawer-open':'drawer-close'"
    >
      <div class="caption_sp">あなたの知らない小宿に迫る！</div>
      <header>
        <div
          role="banner"
          class="sp_menu"
        >
          <div
            class="drawer-toggle drawer-hamburger"
            @click="isHamburger=!isHamburger"
          >
            <span class="sr-only">toggle navigation</span>
            <span class="drawer-hamburger-icon"></span>
          </div>

          <nav
            class="drawer-nav"
            role="navigation"
          >
            <div
              class="wrap"
              :style="`transition-timing-function: cubic-bezier(0.1, 0.57, 0.1, 1); transition-duration: 0ms; transform: translate(0px, -${windowTop}px) translateZ(0px);`"
            >
            <iframe src="https://yado-sagashi.com/parts/travel-menu-sp/" width="100%" height="3000" frameborder="0" scrolling="no"></iframe> 
            </div>
          </nav>

        </div>
        <!-- sp_menu end  -->
        <div class="header_wrap">
          <div class="header_box">
            <div class="logo pc-m-logo"><a :href="`${wpLink}`"><img
                  src="@/assets/images/logo.svg"
                  width="200"
                  height="auto"
                  alt=""
                ></a><span class="caption">あなたの知らない小宿に迫る！</span></div>
            <div class="header_menu">
              <div class="sns"> <a
                  href="https://www.youtube.com/channel/UCZHIVCooPuLAfZXJlDcbXeg"
                  target="_blank"
                ><img
                    src="@/assets/icons/header_icon_yt.svg"
                    width="auto"
                    height="27"
                    alt="Youtube"
                  ></a> <a
                  href="https://www.facebook.com/tabinekonotabi/"
                  target="_blank"
                ><img
                    src="@/assets/icons/header_icon_fb.svg"
                    width="32"
                    height="auto"
                    alt="Facebook"
                  ></a> <a
                  href="https://twitter.com/yadosagashi"
                  target="_blank"
                ><img
                    src="@/assets/icons/header_icon_tw.svg"
                    width="32"
                    height="auto"
                    alt="Twitter"
                  ></a> <a
                  href="https://www.instagram.com/tabinekonotabi/"
                  target="_blank"
                ><img
                    src="@/assets/icons/header_icon_inst.png"
                    width="30"
                    height="auto"
                    alt="Instagram"
                  ></a>
                <!-- <img src="https://yado-sagashi.com/wp-content/themes/yadosagashi_2021/img/header_icon_line.svg" width="28" height="26" alt="Line"/> -->
              </div>
            </div>
          </div>
        </div>
        <!-- header_wrap end  -->
        <!-- category_menu start  -->
        <!-- category_menu end  -->
        <div class="sp_menu_main">
          <a
            :href="`${wpLink}travel`"
            class="btn"
          ><img
              src="@/assets/icons/sp_menu_travel.svg"
              width="161"
              height="auto"
              alt=""
            ></a>
          <a
            :href="`${wpLink}movie`"
            class="btn"
          ><img
              src="@/assets/icons/sp_menu_movie.svg"
              width="161"
              height="auto"
              alt=""
            ></a>
          <a
            @click="facilityListLink()"
            class="btn"
          ><img
              src="@/assets/icons/sp_menu_stay.svg"
              width="161"
              height="auto"
              alt=""
            ></a>
        </div>
        <!-- sp_menu_main end  -->
      </header>
    </div>

    <!-- sp end  -->

    <div class="category_menu">
      <div class="box">
        <!-- @mouseleave="travelHoverd = true" -->
        <a
          @mouseover="travelHoverd = true"
          @mouseleave="travelHoverd = false"
          :href="`${wpLink}travel`"
          class="travel-button"
        >
          <i class="text"></i>
        </a>
        <a
          :href="`${wpLink}movie`"
          class="movie-button"
        >
          <i class="text"></i>
        </a>
        <a
          @click="facilityListLink()"
          class="stay-button"
        >
          <i class="text"></i>
        </a>
        <div
          class="menu_travel_box menu_box "
          :class="travelHoverd ? 'visible':'dd'"
        >
          <div
            @mouseenter="travelHoverd = true"
            @mouseleave="travelHoverd = false"
            class="contents"
          >
            <iframe
              src="https://yado-sagashi.com/parts/travel-menu/"
              width="100%"
              height="550"
              allowtransparency="true"
              frameborder="0"
              scrolling="no"
            ></iframe>

          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- ================ header end =================== -->
</template>

<script>
import axios from "axios";
import config from "@/config";
import "@/assets/scss/drawer.scss";

export default {
  name: "TheHeader",
  components: {
    // TheHeaderDropdownAccnt
  },
  props: ["isMobile"],
  data() {
    return {
      loading: false,
      openFgList: false,
      isHamburger: false,
      travelHoverd: false,
      areaFilter: false,
      tagList: [],
      categoryList: [],
      specilalFeatureList: [],
      wpLink: config.WPSITE,
      windowTop: 0,
      langs: ["ja", "en", "zh"],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      this.windowTop =
        window.top.scrollY; /* or: e.target.documentElement.scrollTop */
    },

    /** multi language flag toggle */
    openLG() {
      this.openFgList = !this.openFgList;
    },
    /**
     *  button: go to facility list page
     *
     */
    facilityListLink() {
      this.$router.push({
        name: "FacilityListView",
      });
    },
    /** get tag list from wordpress site
     *
     */
    getAllTagList() {
      var urlRequest = `${config.WPSITE}?rest_route=/wl/v1/tags`;
      axios
        .get(urlRequest, {})
        .then((resp) => {
          this.tagList = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /** get all category list from wordpress site
     *
     */
    getAllCategoryList() {
      var urlRequest = `${config.WPSITE}?rest_route=/wl/v1/categorys`;
      axios
        .get(urlRequest, {})
        .then((resp) => {
          var catData = resp.data;
          this.categoryList = catData.map((cat) => {
            cat.tabActive = false;
            return cat;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /** get all special feature list from wordpress site */
    getAllSpecialFeatureList() {
      var urlRequest = `${config.WPSITE}?rest_route=/wl/v1/features`;
      axios
        .get(urlRequest, {})
        .then((resp) => {
          this.specilalFeatureList = resp.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  computed: {
    /** multi language flag filter */
    localFlag() {
      var currentLg;
      this.$i18n.locale == "ja"
        ? (currentLg = "cif-jp")
        : this.$i18n.locale == "en"
        ? (currentLg = "cif-us")
        : (currentLg = "cif-cn");
      return currentLg;
    },
  },

  beforeMount() {
    this.getAllTagList();
    this.getAllCategoryList();
    this.getAllSpecialFeatureList();
  },
};
</script>
<style lang="scss" scoped>
body {
  overflow: auto;
}
a {
  cursor: pointer;
}
.drawer-hamburger,
.caption_sp {
  display: none;
}
header .sp_menu_main {
  display: none;
}
// .header-section {
//   header {
//     border-bottom: #cacaca 1px solid;
//   }
//   .pc-header {
//     padding: 15px;
//     min-width: 1300px;
//     .header_box {
//       max-width: 1000px;
//       margin: 0 auto;
//       display: flex;
//       flex-wrap: wrap;
//       justify-content: space-between;
//       align-items: center;
//       .pc-submenu {
//         line-height: 1.4em;
//         a {
//           margin-left: 6px;
//           font-size: 12px;
//         }
//       }
//     }
//     .logo {
//       width: auto;
//       transition: opacity 0.5s ease-out;
//       padding-bottom: 6px;
//       &:hover {
//         opacity: 0.5;
//       }
//       .caption {
//         margin: 0 0 0 20px;
//         max-width: 500px;
//         font-size: 13px;
//       }
//     }
//     .caption_sp {
//       display: none;
//     }
//     .header_menu {
//       width: auto;
//     }
//     .sns {
//       width: 300px;
//       margin: -6px 0 0 auto;
//       display: flex;
//       flex-wrap: wrap;
//       justify-content: flex-end;
//       align-items: center;
//       a {
//         margin: 0 24px 0 0;
//       }
//       img {
//         max-width: 100%;
//       }
//     }
//   }
// }
header .header_wrap {
  border-bottom: #cacaca 1px solid;
  padding: 10px;
  min-width: 1300px;
}
header .header_box {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
header .logo {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.pc-m-logo img {
  margin-bottom: 6px;
}
header .logo .caption {
  margin: 0 0 0 20px;
  max-width: 500px;
  font-size: 13px;
}
header .sns {
  width: 300px;
  margin: -7px 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
header .sns a {
  margin: 0 24px 0 0;
}
header .sns a:first-child {
  img {
    margin-top: 2px;
  }
}
// header end
.category_menu {
  border-bottom: #cacaca 1px solid;
  position: relative;
  min-width: 1300px;
  .box {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .travel-button,
  .movie-button,
  .stay-button {
    flex: 1;
    width: auto;
    height: 62px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #d8481c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: #cacaca 1px solid;

    &:hover {
      background: #0b825d;
    }
  }
  .stay-button {
    border-right: #cacaca 1px solid;
  }

  .travel-button {
    i {
      background: url("~@/assets/icons/header_menu_travel.svg") no-repeat center
        center;
      display: block;
      width: 173px;
      height: 19px;
    }
    &:hover {
      i {
        background: url("~@/assets/icons/header_menu_travel_w.svg") no-repeat
          center center;
      }
    }
  }

  .movie-button {
    i {
      background: url("~@/assets/icons/header_menu_movie.svg") no-repeat center
        center;
      display: block;
      width: 226px;
      height: 19px;
    }
    &:hover {
      i {
        background: url("~@/assets/icons/header_menu_movie_w.svg") no-repeat
          center center;
      }
    }
  }

  .stay-button {
    i {
      background: url("~@/assets/icons/header_menu_search.svg") no-repeat center
        center;
      display: block;
      width: 161px;
      height: 21px;
    }
    &:hover {
      i {
        background: url("~@/assets/icons/header_menu_search_w.svg") no-repeat
          center center;
      }
    }
  }
}
.hb-banner {
  display: none;
}

.category_menu .menu_travel_box {
  width: 100%;
  height: 500px;
  background: rgba(11, 130, 93, 0.95);
  position: absolute;
  top: 62px;
  right: 0;
  z-index: 5000;
  opacity: 0;
  visibility: hidden;
  transition: 300ms;
}
.category_menu .menu_travel_box .contents {
  padding: 40px;
  font-size: 14px;
  color: #000;
}
.category_menu .menu_travel_box.visible {
  opacity: 1;
  visibility: visible;
}
.category_menu .menu_travel_box h2 {
  margin: 0 0 15px 0;
  padding: 0 0 0 20px;
  font-size: 16px;
  border-left: #fff 4px solid;
  color: #fff;
  text-align: left;
}
.header_menu_travel {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.header_menu_travel a:hover {
  opacity: 0.5;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
}
.header_menu_travel a:hover img {
  opacity: 0.5;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
}
.header_menu_travel a {
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
}
.header_menu_travel a img {
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
}

.header_menu_travel ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.header_menu_travel ::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 5px;
}
.header_menu_travel .tags {
  width: 28%;
}
.header_menu_travel .tags ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header_menu_travel .tags li {
  border: none !important;
  height: auto;
  width: 200px !important;
  font-size: 14px;
  display: block;
}
.header_menu_travel .tags ul.tag_cloud_home li a {
  background-color: #fff;
  border: 1px solid #0b825d;
  color: #0b825d;
}
.header_menu_travel .tags ul.tag_cloud_home a:hover {
  opacity: 0.5 !important;
  -webkit-opacity: 0.5 !important;
  -moz-opacity: 0.5 !important;
}
.header_menu_travel .tags ul.tag_cloud_home a:hover img {
  opacity: 0.5 !important;
  -webkit-opacity: 0.5 !important;
  -moz-opacity: 0.5 !important;
}
.header_menu_travel .tags .tag_btn {
  border: #fff 1px solid;
  color: #fff;
}

.header_menu_travel .cat {
  width: 28%;
  margin: 0 0 0 25px;
}
.header_menu_travel .cat .list {
  overflow-y: auto;
  height: 400px;
  text-align: left;
}
.header_menu_travel .cat .list li {
  border-bottom: #fff 1px solid;
  margin: 0;
  padding: 20px 0 10px 0;
  background: url("~@/assets/icons/link_icon01_w.svg") no-repeat center right
    12px/8px;
}
.header_menu_travel .cat .list li a {
  display: block;
  color: #fff;
}

.header_menu_travel .series {
  width: 28%;
}
.header_menu_travel .series .list {
  overflow-y: auto;
  height: auto;
  text-align: left;
  margin: 0 0 20px 0;
}
.header_menu_travel .series .list li {
  border-bottom: #fff 1px solid;
  margin: 0;
  padding: 10px 0;
  background: url("~@/assets/icons/link_icon01_w.svg") no-repeat center right
    12px/8px;
}
.header_menu_travel .series .list li a {
  display: block;
  color: #fff;
  font-weight: bold;
}
.header_menu_travel .series .writer_btn {
  padding: 8px 0;
  border: none;
  color: #0b825d;
  background: #fff url("~@/assets/icons/link_icon01_w.svg") no-repeat center
    right 12px/8px;
}
.header_menu_travel .series .tag_btn {
  border: #fff 1px solid;
  color: #fff;
}

.header_menu_travel .accordion {
  padding: 0 10px 0 0;
}
.header_menu_travel .accordion-header,
.accordion-body {
  background: none;
}
.header_menu_travel .accordion-header,
.accordion-body a {
  padding: 0 20px;
}
.header_menu_travel .accordion-header {
  padding: 10px 0;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: bold;
}
.header_menu_travel .accordion-header a {
  color: #fff;
  font-weight: bold;
}
// .header_menu_travel .accordion-body {
//   display: none;
// }
.header_menu_travel .accordion-body__contents {
}
.header_menu_travel .accordion-body__contents li {
  border-bottom: #fff 1px solid;
  // background: rgba(255, 255, 255, 0.15) url("~@/assets/icons/link_icon01_w.svg")
  //   no-repeat center right 10px/9px !important;
  margin: 0;
  padding: 10px 10px 10px 0 !important;
  display: block;
}
.header_menu_travel .accordion-body__contents li a {
  font-weight: bold;
  color: #fff;
}
.header_menu_travel .accordion__item.active:last-child .accordion-header {
  border-radius: none;
}
.header_menu_travel .accordion > .accordion__item > .accordion-header {
  border-bottom: #fff 1px solid;
}
.header_menu_travel .accordion__item > .accordion-header:after {
  font-family: "Ionicons";
  content: url("~@/assets/icons/arrow-down.png");
  // background: url("~@/assets/icons/arrow-down.png") no-repeat center center;

  font-size: 1;
  float: right;
  position: relative;
  top: 0;
  transition: 0.3s all;
  transform: rotate(0deg);
}
.header_menu_travel .accordion__item.actTab > .accordion-header:after {
  transform: rotate(-180deg);
}
.header_menu_travel .accordion__item.actTab .accordion-header {
}
.header_menu_travel .accordion__item .accordion__item .accordion-header {
}

.header_menu_travel .writer_btn {
  text-align: center;
}
.writer_btn {
  padding: 7px;
  margin: 0 0 40px 0;
  text-align: left;
  border-top: #000 1px solid;
  border-bottom: #000 1px solid;
  width: 100%;
  font-weight: bold;
  font-size: 13px;
  display: block;
  // background: url("~@/assets/icons/link_icon01.svg") no-repeat right;
  background-size: 8px;
}
.header_menu_travel .tags .tag_btn {
  border: #fff 1px solid;
  color: #fff;
}

.tag_btn {
  padding: 6px;
  margin: 0 0 20px 0;
  text-align: center;
  border: #000 1px solid;
  width: 100%;
  font-weight: bold;
  font-size: 13px;
  display: block;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
ul.tag_cloud_home {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
ul.tag_cloud_home li {
  margin: 0 5px 5px 0;
  padding: 0;
  flex: 1;
  min-width: 30%;
}
ul.tag_cloud_home li a {
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #3892d0;
  border-radius: 3px;
  color: #3892d0;
  font-size: 12px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.5em;
  &:before {
    content: "#";
    margin: 0 5px 0 0;
  }
}

.category_menu .menu_box h2 {
  margin: 0 0 15px 0;
  padding: 0 0 0 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5em;
  border-left: #fff 4px solid;
  color: #fff;
  text-align: left;
}

// transition start

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 400px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
// transition end

// .trans_slide-enter-active, .trans_slide-leave-active {
//     transition: all .5s;
// }
// .trans_slide-enter, .trans_slide-leave-to {
//     height: 0;
// }

.trans_slide-out-enter,
.trans_slide-out-leave-to {
  opacity: 0;
  transform: translateY(-100px);
  margin-bottom: -100px;
}

@media only screen and (max-width: 413px) {
  .pagination {
    font-size: 12px;
    line-height: 50px;
  }

  .pagination span,
  .pagination a {
    padding: 8px 10px 8px 10px;
  }

  .pagination .current {
    padding: 8px 10px 8px 10px;
  }
}

@media only screen and (max-width: 767px) {
  body {
    min-width: inherit;
  }

  header {
    position: relative;
    .header_box {
      max-width: 1000px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  header .header_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: #cacaca 1px solid;
    width: auto;
    padding: 10px;
    min-width: inherit;
  }

  header .logo {
    width: 100%;
    text-align: center;
  }
  .sp_m_logo img {
    margin-bottom: 6px;
  }

  header .logo img {
    display: block;
    margin: 0 auto;
    max-width: 130px;
    height: auto;
  }

  header .logo {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  header .logo .caption {
    display: none;
  }

  header .member {
    display: none;
  }

  .header_menu {
    display: none;
  }

  header .sp_menu_main {
    display: block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    // border-bottom: #cacaca 1px solid;
  }

  header .sp_menu_main .btn {
    flex: 1;
    border-right: #cacaca 1px solid;
    height: 80px;
    padding: 0;
    display: flex;
    align-items: center;
  }

  header .sp_menu_main .btn:last-child {
    border: none;
  }

  header .sp_menu_main .btn img {
    width: 90%;
    max-width: 160px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  header .sp_menu_main {
    display: block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-bottom: #cacaca 1px solid;
  }
  footer {
    background: #000;
    padding: 20px 0 0 0;
    color: #fff;
    font-size: 14px;
    min-width: inherit;
  }

  footer a {
    color: #fff;
  }

  footer .footer_menu {
    width: auto;
    margin: 0 auto 0 auto;
    padding: 20px 20px 0 20px;
    display: flex;
  }

  footer .footer_menu a {
  }

  footer .footer_menu .lang {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 400px;
  }

  footer .footer_menu .lang a.current {
    border-bottom: #fff 2px solid;
  }

  footer .footer_menu .lang h2 {
    font-size: 16px;
  }

  footer .submenu {
    display: none;
  }

  footer .submenu .box {
    width: 150px;
  }

  footer .submenu .box a {
    font-size: 12px;
    color: #fff;
    display: block;
    margin: 0 0 5px 0;
  }

  footer .submenu .box h2 {
    font-size: 15px;
    margin: 0 0 10px 0;
  }

  footer .logo {
    width: 100%;
    text-align: center;
    margin: 0;
    order: 2;
  }

  footer .logo a {
    margin: 0 auto;
    width: 30% !important;
    display: block;
  }

  footer .logo a img {
    width: 100%;
    height: auto;
  }

  footer .sns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 0 40px 0;
    padding: 0 0 30px 0;
    order: 1;
    border-bottom: #cacaca 1px solid;
  }

  footer .sns a {
    margin: 0 12px;
    width: 10%;
  }

  footer .sns a img {
    width: 100%;
    height: auto;
  }

  footer .copylight {
    margin: 0 auto;
    font-size: 10px;
    padding: 10px;
    text-align: center;
  }

  footer .icon {
    width: 100%;
    text-align: center;
  }

  .caption_sp {
    display: block;
    padding: 5px;
    text-align: center;
    background: #0b825d;
    color: #fff;
    font-size: 12px;
    line-height: 18px !important;
  }

  .drawer-hamburger {
    position: absolute;
  }

  .drawer-hamburger {
    display: block !important;
    position: absolute !important;
    background: #fff !important;
  }

  .drawer-nav {
    width: auto;
    padding: 0;
    // z-index: 2000;
  }

  .drawer-nav h1 {
    margin: 20px 0;
    text-align: center;
  }

  .drawer-nav ul.menu {
    margin: 0 0 40px 0;
  }

  .drawer-nav ul.submenu {
    margin: 0 0 40px 0;
  }

  .drawer-nav ul li {
    margin: 0 0 20px 0;
  }

  .drawer-nav ul li a {
    font-size: 16px;
  }

  .drawer-nav ul li.border {
    border-bottom: #cacaca 1px solid;
    padding: 10px 0;
  }

  .drawer-nav .sns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .sp_menu .wrap {
    padding: 0 0 0 0;
    min-height: 4000px;
  }

  .sp_menu .menu {
    display: block;
  }

  .sp_menu .sp_menu_cat {
    background: #0b825d;
    border-bottom: #fff 1px solid;
    text-align: center;
    color: #fff;
    margin: 0 0 0 0 !important;
    font-size: 18px !important;
    letter-spacing: 0.1em;
    display: block;
    padding: 20px 20px;
    font-weight: bold;
  }

  .sp_menu .sp_menu_cat h2 {
    font-size: 18px !important;
    font-weight: bold;
    margin: 0;
    line-height: 27px;
  }

  .sp_menu .sp_menu_cat img {
    display: block;
    margin: 0 auto;
  }

  .sp_menu ul a {
    display: block;
    padding: 20px 20px;
    font-weight: bold;
  }

  .sp_menu ul.menu {
    padding: 0;
    margin: 0;
    font-size: 18px;
    flex: inherit;
  }

  .sp_menu ul.menu li {
    border-bottom: #cacaca 1px solid;
    margin: 0;
    background: url("~@/assets/icons/link_icon01.svg") no-repeat center right
      12px/10px;
  }

  .sp_menu ul.menu li a {
    color: #000;
    line-height: 24px;
  }

  .sp_menu ul.list li {
    border-bottom: #cacaca 1px solid;
    background: #eeeeee url("~@/assets/icons/link_icon01.svg") no-repeat center
      right 12px/10px;
    margin: 0;
  }

  .sp_menu ul.list li a {
    padding: 20px 40px;
    font-weight: bold;
  }

  .sp_menu ul.menu .btn_wrap {
    background: none;
  }

  .sp_menu ul.menu .btn {
    padding: 19px;
    margin: 40px auto;
    background: #0092d2;
    border-radius: 50px;
    width: 80%;
  }

  .sp_menu ul.menu .btn img {
    display: block;
    margin: 0 auto;
  }

  .sp_menu .cat_link {
    display: inline;
  }

  .sp_menu .sns {
    padding: 40px 0;
  }

  .index_banner_box {
    margin: 0;
    width: 100vw;
    height: auto;
  }

  .category_menu {
    border-bottom: #cacaca 1px solid;
    display: none;
  }

  .breadcrumb {
    width: auto;
    height: inherit;
    margin: 0 auto 0 0;
    padding: 20px;
    background: #f2f2f2;
    border-top: #cacaca 2px solid;
    display: block;
  }

  .main_contents {
    width: auto;
    margin: 0 auto;
    padding: 0 20px;
    min-height: inherit;
    display: inherit;
  }

  .main_contents .contents_box {
    width: auto;
  }

  .main_contents .contents_box_page {
    width: auto;
    padding: 40px 0;
    min-height: 50vh;
  }

  .main_contents .contents_body {
    width: auto;
    padding: 0 0 40px 0;
    margin: 40px 0 0 0;
  }

  .main_contents .contents_body .eyecatch {
    margin: 0 0 20px 0;
  }

  .main_contents .contents_body .eyecatch img {
    width: 100%;
    height: auto;
  }

  .main_contents .contents_body .meta {
    margin: 0 0 15px 0;
  }

  .main_contents .contents_body h1 {
    font-size: 32px;
    font-weight: normal;
    margin: 0 0 15px 0;
  }

  .main_contents .contents_body .meta .date {
    margin: 0 0 20px 0;
  }

  .main_contents .contents_body .meta .cat {
    margin: 0 0 0 10px;
    color: #3892d0;
    font-size: 13px;
    padding: 2px 20px;
    font-weight: bold;
    border: #3892d0 1px solid;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }

  .main_contents .contents_body .post h2 {
    margin: 0 0 25px 0;
    padding: 0 0 10px 0;
    border-bottom: #000 2px solid;
  }

  .main_contents .contents_body .post p {
    margin: 0 0 25px 0;
  }

  .main_contents .contents_body .embeded {
    margin: 0 0 40px 0;
    position: relative;
    width: 100%;
  }

  .main_contents .contents_body .embeded :before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }

  .main_contents .contents_body .embeded iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .main_contents .contents_body_column {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .main_contents .contents_body_column .post {
    width: 48%;
    border: none;
    margin: 0 0 40px 0;
  }

  .main_contents .contents_body_column .post .meta {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 5px 0;
  }

  .main_contents .contents_body_column .post .meta .cat {
    border: #0091d1 1px solid;
    padding: 2px 10px;
    border-radius: 30px;
    font-size: 12px;
    color: #0091d1;
    font-weight: bold;
  }

  .main_contents .contents_body_column .post .meta .cat a {
    font-size: 12px;
    color: #0091d1;
    font-weight: bold;
  }

  .main_contents .contents_body_column .post.meta .date {
    color: #666666;
  }

  .main_contents .contents_body_column .post.meta .date {
    color: #666666;
  }

  .main_contents .contents_body_column .post .eyecatch {
    width: 100%;
    margin: 0 0 20px 0;
    position: relative;
  }

  .main_contents .contents_body_column .post .eyecatch img {
    width: 100%;
    height: auto;
    display: block;
  }

  .main_contents .contents_body_column .post .editor {
    margin: 0 0 20px 0;
    width: 100%;
    display: block;
    text-align: right;
  }

  .main_contents .contents_body_column .post .related {
    margin: 40px 0;
  }

  .main_contents .contents_body_column .post .like {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .main_contents .contents_body_column .post a {
    font-weight: bold;
  }

  .main_contents .contents_body_single {
    display: block;
    width: 100%;
  }

  .main_contents .contents_body_single .post {
    width: auto;
    border: none;
    margin: 0 0 40px 0;
  }

  .main_contents .contents_body_single .post .meta {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 5px 0;
  }

  .main_contents .contents_body_single .post .meta .cat {
    border: #0091d1 1px solid;
    padding: 2px 10px;
    border-radius: 30px;
    font-size: 12px;
    color: #0091d1;
    font-weight: bold;
  }

  .main_contents .contents_body_single .post .meta .cat a {
    font-size: 12px;
    color: #0091d1;
    font-weight: bold;
  }

  .main_contents .contents_body_single .post.meta .date {
    color: #666666;
  }

  .main_contents .contents_body_single .post.meta .date {
    color: #666666;
  }

  .main_contents .contents_body_single .post .eyecatch {
    width: 100%;
    margin: 0 0 20px 0;
    position: relative;
  }

  .main_contents .contents_body_single .post .eyecatch img {
    width: 100%;
    height: auto;
    display: block;
  }

  .main_contents .contents_body_single .post .editor {
    margin: 10px 0 20px 0;
    width: 100%;
    display: block;
    text-align: right;
  }

  .main_contents .contents_body_single .post .related {
    margin: 40px 0;
  }

  .main_contents .contents_body_single .post .like {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .main_contents .contents_body_single .post a {
    font-weight: bold;
  }

  .main_contents .contents_body_single .author_box {
    padding: 0;
  }

  .main_contents .contents_body .share {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: #cacaca 1px solid;
    width: auto;
    padding: 30px 0 0 0;
    margin: 30px 0 30px 0;
  }

  .main_contents .contents_body .share .sns {
    width: 100%;
  }

  .main_contents .contents_body .share .like {
    width: 100%;
    text-align: center;
    margin: 0 0 30px 0;
  }

  .main_contents .contents_body .share .like .wpulike {
    padding: 0;
  }

  .main_contents .contents_body .share_2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: #cacaca 1px solid;
    width: auto;
    padding: 30px 0 0 0;
    margin: 0 0 30px 0;
  }

  .main_contents .contents_body .share_2 .sns {
    width: 250px;
  }

  .main_contents .contents_body .share_2 .like {
    width: auto;
    text-align: center;
    margin: 0 0 30px 0;
  }

  .main_contents .contents_body .share_2 .like .wpulike {
    padding: 0;
  }

  .main_contents .column {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .main_contents .contents_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 48%;
    border-bottom: none;
    padding: 10px 0 0 0;
    margin: 0 0 10px 0;
  }

  .main_contents .contents_list .eyecatch {
    width: 108%;
    order: 1;
    position: relative;
    margin: 0 0 10px 0;
  }

  .main_contents .contents_list .eyecatch .like {
    position: absolute;
    bottom: 10px;
    right: 5px;
  }

  .main_contents .search_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .main_contents .search_list .author_box {
    padding: 0;
  }

  .main_contents .search_list .eyecatch {
    height: auto;
  }

  .main_contents .contents_list .list_contents {
    width: 100%;
    order: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
  }

  .main_contents .contents_list .list_contents h1 {
    font-size: 15px;
    font-weight: bold;
  }

  .main_contents .contents_list .list_contents a {
    font-size: 14px !important;
    font-weight: bold;
  }

  .main_contents .contents_list .editor {
    margin: 10px 0 0 0;
    width: 100%;
    display: block;
    text-align: right;
  }

  .main_contents .contents_list .author_box {
    width: auto;
  }

  .main_contents .contents_list .meta {
    margin: 0 0 10px 0;
  }

  .main_contents .contents_list .meta .date {
    display: block;
    margin: 0 0 5px 0;
  }

  .main_contents .contents_list .meta .cat {
    margin: 0 0 10px 0;
  }

  .main_contents .side {
    width: auto;
    margin: 40px 0 0 0;
    display: none;
  }

  .main_contents .contents_side {
    width: auto;
    margin: 40px 0 0 0;
    display: none;
  }

  .main_contents .side_sp {
    width: 100%;
    padding: 40px 0 0 0;
    margin: 40px 0;
    border-top: #cacaca 1px solid;
  }

  .main_contents .side_sp h2 {
    margin: 0 0 15px 0;
    font-size: 14px;
    border-left: #000 4px solid;
    padding: 0 0 0 10px;
  }

  .main_contents .side_sp .most_liked_post {
    width: 100%;
    font-size: 13px;
  }

  .main_contents .side_sp .most_liked_post li {
    list-style: decimal;
    list-style-position: inside;
    margin: 0 0 10px 0;
  }

  .main_contents .side_sp .scroll {
    overflow-x: scroll;
    margin: 0 0 40px 0;
  }

  .main_contents .side_sp .scroll .box {
    width: 200px;
    margin: 0 20px 0 0;
  }

  .main_contents .side_sp .scroll .box a {
    font-weight: bold;
  }

  .main_contents .side_sp .scroll_wrap {
    width: 1100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .main_contents .contents_body .yado_detail .yado_box {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .main_contents .contents_body .yado_detail .yado_box .yado_img {
    width: 100%;
  }

  .main_contents .contents_body .yado_detail .yado_box .yado_img img {
    width: 100%;
    height: auto;
  }

  .main_contents .contents_body .yado_detail .yado_box .yado_info {
    width: 100%;
    font-size: 14px;
  }

  .main_contents .contents_body .yado_detail .yado_box .yado_info th {
    width: 100%;
    text-align: left;
    display: block;
  }

  .main_contents .contents_body .yado_detail .yado_box .yado_info td {
    width: 100%;
    text-align: left;
    display: block;
  }

  .main_contents .contents_body .yado_detail .yado_box .reserve_btn {
    margin: 20px 0 0 0;
    padding: 10px 0;
    font-size: 20px;
    display: block;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .main_contents_travel {
    width: auto;
    margin: 0 auto;
    padding: 40px 20px;
  }

  .main_contents_travel .alm-reveal {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100% !important;
  }

  .main_contents_travel .alm-reveal .list_contents {
    width: 50%;
    padding: 0 10px;
    margin: 0 0 20px 0;
  }

  .main_contents_travel .alm-reveal .list_contents .eyecatch {
    width: 100%;
  }

  .main_contents_travel .alm-reveal .list_contents .eyecatch a {
    width: 100%;
  }

  .main_contents_travel .alm-reveal .list_contents .eyecatch img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .main_contents_travel .alm-reveal .list_contents .meta {
    width: 100%;
    margin: 10px 0 0 0;
  }

  .main_contents_travel .alm-reveal .list_contents .meta a {
    font-size: 14px;
    font-weight: bold;
  }

  .main_contents_travel .alm-reveal .list_contents:hover .hover_meta {
    opacity: 100;
  }

  .main_contents_travel .alm-reveal .list_contents .hover_meta {
    display: none;
  }

  .main_contents_travel .alm-reveal .list_contents .hover_meta .box {
    padding: 20px;
    color: #fff;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .main_contents_travel .alm-reveal .list_contents .hover_meta .box a {
    color: #fff;
    line-height: 1.8em;
  }

  .main_contents_travel .alm-reveal .list_contents .hover_meta .box .cat {
    font-size: 21px;
    position: absolute;
    font-weight: bold;
    line-height: 1.5em;
    width: 90%;
  }

  .main_contents_travel
    .alm-reveal
    .list_contents
    .hover_meta
    .box
    .author_mini {
    font-size: 21px;
    position: absolute;
    bottom: 0;
    left: 20px;
  }

  .special_intro {
    margin: 0 0 40px 0;
  }

  .special_intro .hero_img {
    margin: 0 0 0 0;
  }

  .special_intro .hero_img img {
    width: 100%;
    height: auto;
  }

  .special_intro .share {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0 0 0;
    padding: 0 20px;
  }

  .special_intro .share .sns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 85%;
    order: 2;
  }

  .special_intro .share .sns div {
    margin: 0 3px 0 0;
  }

  .special_intro .share .sns a {
    margin: 0 12px;
    width: 10%;
  }

  .special_intro .share .sns a img {
    width: 100%;
    height: auto;
  }

  .special_intro .share .like {
    margin: 0;
    width: 10%;
    order: 1;
    left: 0;
  }

  .special_intro .text {
    padding: 0 20px;
    margin: 0 0 20px 0;
  }

  .series_title_box h2 {
    margin: 20px 0 0 0;
  }

  .main_contents .contents_body .info_box {
    width: auto;
    margin: 60px auto;
  }

  .main_contents .contents_body .info_box .wrap {
    padding: 30px 20px;
    margin: 0 0 20px 0;
    border: #cacaca 1px solid;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .main_contents .contents_body .info_box h2 {
    font-size: 20px;
    margin: 0 0 10px 0;
  }

  .main_contents .contents_body .info_box h3 {
    font-size: 18px;
    margin: 0 0 10px 0;
  }

  .main_contents .contents_body .info_box table {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .main_contents .contents_body .info_box th {
    width: 20%;
    text-align: left;
  }

  .main_contents .contents_body .info_box td {
    width: 80%;
    text-align: left;
  }

  .main_contents .contents_body .info_box .hp_btn {
    width: 100%;
    margin: 20px 0 0 0;
    padding: 10px 0;
    font-size: 16px;
    display: block;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .main_contents .contents_body .info_box .text_box {
    width: 100%;
  }

  .main_contents .contents_body .img_box {
    width: 100% !important;
    text-align: center;
  }

  .main_contents .contents_body .img_box img {
    margin: 0 auto;
  }

  .index_feature {
    display: block;
    width: auto;
    min-width: inherit;
    margin: 0 auto;
  }

  .index_feature_wrap {
    border-bottom: #cacaca 1px solid;
    margin: 0 0 40px 0;
  }

  .index_feature .slider_box {
    width: 100%;
    height: auto;
    background: #eee;
  }

  .index_feature .slider_box .swiper-slide {
    overflow: hidden;
    height: auto;
  }

  .index_feature .slider_box .swiper-slide img {
    width: 100%;
    height: auto;
    display: block;
  }

  .index_feature .pickup_box {
    width: auto;
    padding: 0;
  }

  .index_feature .pickup_box .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: #cacaca 1px solid;
    padding: 20px;
  }

  .index_feature .pickup_box .list:last-child {
    border-bottom: none;
  }

  .index_feature .pickup_box .list .img_box {
    width: 45%;
    height: auto;
    background: #eee;
    order: 2;
    overflow: hidden;
  }

  .index_feature .pickup_box .list .img_box img {
    display: block;
  }

  .index_feature .pickup_box .list .text_box {
    width: 50%;
    padding: 0;
  }

  .index_feature .pickup_box .list .text_box a {
    font-size: 13px;
  }

  .index_feature .pickup_box .list .tag_info {
    margin: 0 0 10px 0;
    line-height: 2em;
  }

  .index_feature .pickup_box .list .pick_cat {
    font-size: 12px;
    padding: 5px 10px;
    color: #fff;
    background: #000;
    margin: 0 10px 0 0;
  }

  .index_feature .pickup_box .list .area_cat {
    font-size: 12px;
    padding: 5px 10px;
    margin: 10px 0 0 0;
    color: #0091d1;
    border: 1px solid #0091d1;
    line-height: 1.5em;
    display: inline-block;
  }

  .new_contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    width: auto;
    margin: 0 0 30px 0;
  }

  .new_contents .title {
    width: 10%;
    display: none;
  }

  .new_contents .sp_title {
    display: inline;
  }

  .new_contents .new_list {
    width: 100%;
  }

  .new_contents .new_list .post {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    padding: 15px 0;
    border-bottom: #cacaca 1px dotted;
  }

  .new_contents .new_list .meta {
    width: 100%;
    font-size: 12px;
    margin: 0 0 10px 0;
  }

  .new_contents .new_list .link {
    width: 100%;
    font-size: 13px;
    font-weight: bold;
  }

  .new_contents .new_list .meta .date {
    width: auto;
    font-size: 12px;
    color: #666666;
  }

  .new_contents .new_list .meta .cat {
    width: auto;
    font-size: 12px;
    padding: 5px 20px;
    margin: 0 0 0 15px;
    color: #0091d1;
    border: 1px solid #0091d1;
  }

  .new_contents_link {
    width: 100%;
    margin: 0 0 40px 0;
    text-align: center;
  }

  .index_movie {
    background: #ededed;
    width: auto;
    padding: 60px 20px;
    margin: 0 0 40px 0;
    min-width: inherit;
  }

  .index_movie .movie_box {
    width: auto;
    margin: 0 auto;
  }

  .index_movie .movie_box h2 {
    text-align: center;
    margin: 0 0 30px 0;
  }

  .index_movie .movie_box h2 img {
    width: 50%;
    height: auto;
  }

  .index_movie .movie_box .movie_pick {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: auto;
    margin: 0 auto;
    padding: 0 0 10px 0;
    border-bottom: #cacaca 1px solid;
  }

  .index_movie .movie_box .movie_pick .img_box {
    width: auto;
    margin: 0 0 20px 0;
  }

  .index_movie .movie_box .movie_pick .img_box img {
    width: 100%;
    height: auto;
  }

  .index_movie .movie_box .movie_pick .text_box {
    width: auto;
  }

  .index_movie .movie_box .movie_pick .text_box p {
    font-size: 13px;
  }

  .index_movie .movie_box .movie_pick .text_box h3 {
    font-size: 16px;
    margin: 0 0 20px 0;
  }

  .index_movie .movie_box .movie_pick .new {
    font-size: 12px;
    padding: 3px 20px;
    color: #fff;
    background: #cc0000;
    margin: 0 0 10px 0;
    display: inline-block;
  }

  .index_movie .movie_box .movie_pick .text_box .yado_info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 25px 0 0 0;
  }

  .index_movie .movie_box .movie_pick .text_box .yado_info .yado_img {
    width: 30%;
  }

  .index_movie .movie_box .movie_pick .text_box .yado_info .yado_img img {
    width: 100%;
    height: auto;
  }

  .index_movie .movie_box .movie_pick .text_box .yado_info .yado_text {
    width: 65%;
  }

  .index_movie .movie_box .movie_pick .text_box .yado_info .yado_text .caption {
    font-size: 11px;
    padding: 2px 20px;
    color: #000;
    border: #000 1px solid;
    margin: 0 0 5px 0;
    display: inline-block;
  }

  .index_movie .movie_box .movie_pick .text_box .yado_info .yado_text .name {
    font-weight: bold;
  }

  .index_movie .movie_box .movie_pick .addr {
    font-size: 11px !important;
  }

  .index_movie_list {
    width: auto;
    margin: 10px auto 0 auto;
  }

  .index_movie_list .box {
    width: 100%;
    margin: 0 0 10px 0;
    border-bottom: #cacaca 1px solid;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .index_movie_list .box .img_box {
    width: 40%;
  }

  .index_movie_list .box .img_box img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 0 10px 0;
  }

  .index_movie_list .box .text_box {
    width: 55%;
  }

  .index_movie_btn {
    margin: 40px 0 0 0;
    text-align: center;
  }

  .index_movie_btn .movie_link_btn {
    display: inline-block;
    padding: 15px;
    width: 80%;
    border-radius: 50px;
  }

  .index_movie_btn .movie_link_btn img {
    display: block;
    margin: 0 auto;
  }

  .yadotube_btn {
    margin: 40px 0 0 0;
    text-align: center;
  }

  .yadotube_btn .movie_link_btn {
    background: #0091d1;
    display: inline-block;
    padding: 15px;
    width: 340px;
    border-radius: 50px;
  }

  .yadotube_btn .movie_link_btn img {
    display: block;
    margin: 0 auto;
  }

  .index_series_btn .link_btn_w {
    background: #fff url("~@/assets/icons/link_icon01_l.svg") no-repeat center
      left 30px;
    background-size: 10px;
    color: #000;
    border: #000 1px solid;
    display: inline-block;
    padding: 15px;
    width: 100%;
    border-radius: 50px;
    font-weight: bold;
  }

  .index_series_btn .link_btn_w img {
    display: block;
    margin: 0 auto;
  }

  .index_series_btn .link_btn_w a {
    color: #fff;
  }

  .back_story {
    background: #eeeeee;
    margin: 60px 0 0 0;
  }

  .back_story .contents_box {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .back_story .contents_box h2 {
    text-align: center;
    font-size: 18px;
    margin: 0 auto 10px auto;
  }

  .back_story .contents_box .text_box {
    flex: none;
    padding: 10px;
    width: 100% !important;
  }

  .back_story .contents_box .text_box h2 {
    text-align: left;
    font-size: 18px;
  }

  .back_story .contents_box .img_box {
    margin: 0 0 10px 0;
    width: 100% !important;
    border: #fff 4px solid;
    -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }

  .back_story .contents_box .img_box img {
    display: block;
    width: 100%;
    height: auto;
  }

  .index_travel {
    width: auto;
    margin: 0 auto;
    padding: 20px;
  }

  .index_travel h2 {
    text-align: center;
    margin: 0 0 40px 0;
  }

  .index_travel h2 img {
    width: 40%;
    height: auto;
  }

  .index_travel .travel_box_1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
  }

  .index_travel .travel_box_1 .post {
    width: 48%;
    height: auto;
    margin: 0 0 20px 0;
  }

  .index_travel .travel_box_1 .post .img_box {
    position: relative;
    height: inherit;
  }

  .index_travel .travel_box_1 .post .img_box img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 0 10px 0;
  }

  .index_travel .travel_box_1 .post .img_box .like {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .index_travel .travel_box_1 .post .meta {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 5px 0;
  }

  .index_travel .travel_box_1 .post .meta .cat {
    border: #0091d1 1px solid;
    padding: 2px 10px;
    border-radius: 30px;
  }

  .index_travel .travel_box_1 .post .meta .cat a {
    font-size: 12px;
    color: #0091d1;
    font-weight: bold;
  }

  .index_travel .travel_box_1 .post .meta .date {
    color: #666666;
  }

  .index_travel .travel_box_1 .post .post_title {
    margin: 0 0 15px 0;
  }

  .index_travel .travel_box_1 .post .post_title a {
    font-weight: bold;
    font-size: 14px;
  }

  .index_travel .travel_box_1 .post .author_box {
    width: auto;
  }

  .index_travel .travel_box_2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .index_travel .travel_box_2 .post {
    width: 48%;
    height: auto;
    margin: 0 0 20px 0;
  }

  .index_travel .travel_box_2 .post .img_box {
    position: relative;
    height: inherit;
  }

  .index_travel .travel_box_2 .post .img_box img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 0 10px 0;
  }

  .index_travel .travel_box_2 .post .img_box .like {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .index_travel .travel_box_2 .post .meta {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 5px 0;
  }

  .index_travel .travel_box_2 .post .meta .cat {
    border: #0091d1 1px solid;
    padding: 2px 10px;
    border-radius: 30px;
  }

  .index_travel .travel_box_2 .post .meta .cat a {
    font-size: 12px;
    color: #0091d1;
    font-weight: bold;
  }

  .index_travel .travel_box_2 .post .meta .date {
    color: #666666;
  }

  .index_travel .travel_box_2 .post .post_title {
    margin: 0 0 15px 0;
  }

  .index_travel .travel_box_2 .post .post_title a {
    font-weight: bold;
    font-size: 14px;
  }

  .index_travel .travel_box_2 .post .author_box {
    width: auto;
  }

  .index_travel_btn {
    margin: 20px 0 30px 0;
    text-align: center;
  }

  .index_travel_btn .movie_link_btn {
    background: #000;
    display: inline-block;
    padding: 15px;
    width: 80%;
    border-radius: 50px;
  }

  .index_travel_btn .movie_link_btn img {
    display: block;
    margin: 0 auto;
  }

  .index_category_wrap {
    margin: 0 auto 50px auto;
    padding: 50px 20px;
    border-top: #666666 1px solid;
    border-bottom: #666666 1px solid;
    min-width: inherit;
  }

  .index_category {
    width: auto;
    margin: 0 auto 0 auto;
  }

  .index_category h2 {
    text-align: center;
    margin: 0 0 40px 0;
  }

  .index_category h2 img {
    width: 40%;
    height: auto;
  }

  .index_category .cat_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .index_category .cat_btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 610px;
  }

  .index_category .cat_btn a {
    width: 32%;
    margin: 0 0 10px 0;
  }

  .index_category .cat_btn a img {
    width: 100%;
    height: auto;
  }

  .index_category .tags {
    width: auto;
  }

  .index_series {
    margin: 0 0 80px 0;
    padding: 0 20px;
  }

  .index_series_box {
    width: auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .index_series h2 {
    text-align: center;
    margin: 0 0 30px 0;
  }

  .index_series h2 img {
    width: 20%;
    height: auto;
  }

  .index_series .index_series_box .post {
    width: 48%;
    height: auto;
    margin: 0 0 10px 0;
  }

  .index_series .index_series_box .post .img_box {
    position: relative;
    height: inherit;
  }

  .index_series .index_series_box .post .img_box img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 0 10px 0;
  }

  .index_series .index_series_box .post .img_box .like {
    position: absolute;
    bottom: 20px;
    right: 10px;
  }

  .index_series .index_series_box .post .meta {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 5px 0;
  }

  .index_series .index_series_box .post .meta .cat {
    border: #0091d1 1px solid;
    padding: 2px 10px;
    border-radius: 30px;
  }

  .index_series .index_series_box .post .meta .cat a {
    font-size: 12px;
    color: #0091d1;
    font-weight: bold;
  }

  .index_series .index_series_box .post .meta .date {
    color: #666666;
  }

  .index_series .index_series_box .post .post_title {
    margin: 0 0 15px 0;
  }

  .index_series .index_series_box .post .post_title a {
    font-weight: bold;
    font-size: 15px;
  }

  .index_series_btn {
    margin: 0 0 0 0;
    text-align: center;
  }

  .index_series_btn .link_btn {
    background: #000;
    display: inline-block;
    padding: 15px;
    width: 80%;
    border-radius: 50px;
  }

  .index_series_btn .link_btn img {
    display: block;
    margin: 0 auto;
  }

  .kiji_notice {
    margin: 40px 0 0 0;
  }

  .kiji_notice p {
    font-size: 13px;
  }

  .author_post_page .author_kiji {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .author_post_page .author_kiji .post {
    width: 48%;
    height: auto;
    margin: 0 0 40px 0;
  }

  .author_post_page .author_kiji .post .img_box {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 0 20px 0;
    overflow: hidden;
  }

  .author_post_page .author_kiji .post .img_box img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 0 10px 0;
  }

  .author_post_page .author_kiji .post .img_box .like {
    position: absolute;
    bottom: 0;
    right: 10px;
  }

  .author_post_page .author_kiji .post .meta {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
  }

  .author_post_page .author_kiji .post .meta .cat {
    border: #0091d1 1px solid;
    padding: 2px 10px;
    border-radius: 30px;
    font-size: 12px;
    color: #0091d1;
    font-weight: bold;
  }

  .author_post_page .author_kiji .post .meta .cat a {
    font-size: 12px;
    color: #0091d1;
    font-weight: bold;
  }

  .author_post_page .author_kiji .post .meta .date {
    color: #666666;
    margin: 0;
  }

  .author_post_page .author_kiji .post .post_title {
    margin: 0 0 10px 0;
  }

  .author_post_page .author_kiji .post .post_title a {
    font-weight: bold;
    font-size: 15px;
    color: #000;
  }

  .writer_box .page_title {
    margin: 20px 0 0 0;
  }

  .writer_box .author_box {
    margin: 0 auto;
    padding: 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .writer_box .author_box .writer:nth-child(4n) {
    margin: inherit;
  }

  .writer_box .author_box .writer {
    width: 48%;
    margin: 0 0 20px 0;
  }

  .writer_box .author_box .writer .eyecatch {
    margin: 0 0 10px 0;
    text-align: center;
  }

  .writer_box .author_box .writer .eyecatch img {
    width: 90%;
    height: auto;
    border-radius: 50%;
  }

  .writer_box .author_box .writer .name {
    text-align: center;
  }

  .writer_box .author_box .writer .name a {
    text-align: center;
    font-weight: bold;
    color: #0092d2;
  }

  .writer_box .author_box .writer .description {
    text-align: center;
    font-size: 13px;
  }

  .writer_box .author_box .writer .description span {
    margin: 0 10px 0 0;
  }

  /*.related .list{display:flex; flex-wrap: wrap; justify-content:flex-start;}
.related .list .post{width:100%; height: auto; margin:0 0 20px 0;}
.related .list .post:last-child{margin:0;}
.related .list .post .img_box{position: relative; width: 100%; height:200px; margin:0 0 10px 0; overflow: hidden;}
.related .list .post .img_box img{width:100%; height:auto; display: block; margin:0 auto 10px auto;}
.related .list .post .img_box .like{position: absolute; bottom:0; right:10px;}
.related .list .post .meta{display:flex; flex-wrap: wrap; justify-content:space-between; align-items: center; margin:0 0 5px 0;}
.related .list .post .meta .cat{border:#0091d1 1px solid; padding: 2px 10px; border-radius: 30px; font-size:12px; color:#0091d1; font-weight: bold;}
.related .list .post .meta .cat a{font-size:12px; color:#0091d1; font-weight: bold;}
.related .list .post .meta .date{color:#666666; margin: 0;}
.related .list .post .post_title{margin:0 0 10px 0;}
.related .list .post .post_title a{font-weight: bold; font-size:12px; color:#000;}	*/

  .related_scroll {
    overflow-x: scroll;
  }

  .related {
    margin: 0 !important;
    padding: 0 !important;
    width: 750px;
  }

  .yadotube_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 30px 0;
  }

  .yadotube_box .post {
    width: 48%;
    height: auto;
    margin: 0 0 30px 0;
  }

  .yadotube_box .post .img_box {
    position: relative;
  }

  .yadotube_box .post .img_box img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 0 10px 0;
  }

  .yadotube_box .post .img_box .like {
    position: absolute;
    bottom: 0;
    right: 10px;
  }

  .yadotube_box .post .meta {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 5px 0;
  }

  .yadotube_box .post .meta .cat {
    border: #0091d1 1px solid;
    padding: 2px 10px;
    border-radius: 30px;
    font-size: 12px;
    color: #0091d1;
    font-weight: bold;
  }

  .yadotube_box .post .meta .cat a {
    font-size: 12px;
    color: #0091d1;
    font-weight: bold;
  }

  .yadotube_box .post .meta .date {
    color: #666666;
  }

  .yadotube_box .post .post_title {
    margin: 0 0 15px 0;
    font-weight: bold;
    font-size: 15px;
  }

  .yadotube .intro {
    margin: 0 0 30px 0;
  }

  .yadotube .intro p {
    margin: 0 0 30px 0;
  }

  .yadotube h2 {
    margin: 0 0 30px 0;
  }

  .movie_page_btn {
    margin: 40px 0 0 0;
    text-align: center;
  }

  .movie_page_btn .back_link_btn {
  }

  .movie_page_btn .back_link_btn a {
    background: #fff url("~@/assets/icons/link_icon01_l.svg") no-repeat center
      left 20px;
    background-size: 10px;
    width: 100%;
  }

  .movie_page_btn .yado_ch_btn {
  }

  .movie_page_btn .yado_ch_btn a {
    // background: #0091d1 url("../img/link_icon_other.svg") no-repeat center left 20px;
    background-size: 25px;
    width: 100%;
  }

  .tag_list_page {
  }
  .area-text {
    line-height: 24px !important;
  }
  .tag_list_page ul {
    margin: 0 0 20px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .tag_list_page ul a {
    font-size: 18px;
    margin: 0 0 10px 0;
  }

  .tag_list_page .cat-item {
    margin: 0 0 10px 0;
    position: relative;
    width: 100%;
  }

  .tag_list_page .cat-item .child {
    margin: 0 0 20px 0 !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .tag_list_page .cat-item .child li {
    width: auto;
  }

  .tag_list_page .cat-item .child li a {
    border: #0091d1 1px solid;
    margin: 0 5px 5px 0;
    padding: 4px 10px;
    border-radius: 3px;
    font-size: 12px;
    color: #0091d1;
    font-weight: normal;
    line-height: 2.5em;
  }

  .tag_list_page .cat-item a {
    margin: 0 0 20px 0;
    font-size: 14px;
  }

  .tag_list_page a.title {
    margin: 0 0 10px 0;
    display: block;
    font-weight: bold;
    font-size: 16px;
  }

  .tag_list_page a.title::before {
    content: "#";
    padding: 0 5px 0 0;
  }

  .cat_list_page {
    width: 100%;
  }

  .cat_list_page h3 {
  }

  .cat_list_page ul {
    margin: 0 0 20px 0;
  }

  .cat_list_page ul a {
    font-size: 18px;
    margin: 0 0 10px 0;
    display: block;
    line-height: 1.6em;
  }

  .cat_list_page ul.children {
    margin: 0 0 20px 0 !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .cat_list_page ul li {
    margin: 0 !important;
  }

  .cat_list_page ul.children a {
    border: #0091d1 1px solid;
    margin: 0 5px 5px 0;
    padding: 4px 10px;
    border-radius: 3px;
    font-size: 12px;
    color: #0091d1;
    font-weight: normal;
  }

  .cat_list_page .cat-item {
    margin: 0 0 10px 0;
  }

  .cat_list_page .cat-item a {
    margin: 0 0 20px 0;
    font-size: 14px;
  }

  .search_keyword {
    margin: 40px 0;
  }

  .search_keyword .search_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .search_keyword .search_wrap .key {
    width: 80%;
    padding: 10px;
  }

  .search_keyword .search_wrap #submit {
    width: 20%;
    background: #0091d1;
    border: none;
    color: #fff;
  }

  .search_title {
    margin: 0 0 15px 0;
    padding: 0 0 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .search_title h2 {
    font-size: 28px;
    font-weight: normal;
    margin: 0 0 5px 0;
    letter-spacing: 0.05em;
  }

  .search_title_story {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .search_title_story h2 {
    font-size: 28px;
    font-weight: normal;
    margin: 0;
    letter-spacing: 0.05em;
  }

  .result {
    width: 100%;
  }

  .result_info {
    display: block;
    padding: 0;
    margin: 0;
    width: auto;
  }

  .result .title_box {
    display: block;
    width: auto;
  }

  .result .button {
    margin: 20px 0 0 0;
    width: 100%;
    text-align: right;
  }

  .sp_menu .accordion {
    width: 100%;
    margin: 0 auto;
  }

  .sp_menu .accordion-header,
  .accordion-body {
    background: #fff;
  }

  .sp_menu .accordion__item.active:last-child .accordion-header {
    border-radius: none;
    border-bottom: #fff 1px solid;
  }

  .sp_menu .accordion .accordion__item .b-border {
    border-bottom: #fff 1px solid !important;
    a {
      color: #000000;
      padding: 0 20px !important;
    }
  }
  .sp_menu .accordion-header {
    padding: 20px 10px 20px 20px;
    text-transform: uppercase;
    color: #000;
    background: #fff;
    border-bottom: #cacaca 1px solid;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: bold;
    font-size: 16px;
  }

  // .sp_menu .accordion-body {
  //   display: none;
  // }

  .sp_menu .accordion-body__contents {
  }

  .sp_menu .accordion-body__contents li {
    border-bottom: #cacaca 1px solid;
    background: #fff url("~@/assets/icons/link_icon01.svg") no-repeat center
      right 12px/10px !important;
    margin: 0;
    display: block;
  }

  .sp_menu .accordion-body__contents li a {
    padding: 20px 40px;
    font-weight: bold;
  }

  .sp_menu .accordion__item.active:last-child .accordion-header {
    border-radius: none;
    border-bottom: #fff 1px solid;
  }

  .sp_menu .accordion:first-child > .accordion__item > .accordion-header {
    border-bottom: #fff 1px solid;
  }

  .sp_menu .accordion__item > .accordion-header:after {
    font-family: "Ionicons";
    font-size: 1.2em;
    float: right;
    position: relative;
    top: -2px;
    transition: 0.3s all;
    transform: rotate(0deg);
    content: url("~@/assets/icons/link_icon01.png");
  }

  .sp_menu .accordion__item.actTab > .accordion-header:after {
    transform: rotate(-180deg);
  }

  .sp_menu .accordion__item.active .accordion-header {
    background: #cacaca;
  }

  .sp_menu .accordion__item .accordion__item .accordion-header {
    background: #cacaca;
  }

  .wpcf7-form {
    width: auto;
    margin: 0 auto;
    padding: 0;
  }

  .wpcf7-form table {
    margin: 20px 0;
    border-collapse: collapse;
  }

  .wpcf7-form table tr:last-child {
    border-bottom: none;
  }

  .wpcf7-form table th {
    padding: 10px;
    display: block;
    width: 100% !important;
    text-align: left;
  }

  .wpcf7-form table td {
    padding: 10px;
    display: block;
    width: 100% !important;
  }

  .wpcf7-list-item {
    width: auto;
  }

  .wpcf7-radio {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .wpcf7-form form {
    width: 100%;
  }

  .wpcf7-form input {
    width: auto;
    margin: 5px 0;
  }

  .wpcf7-form textarea {
    width: 100%;
    margin: 5px 0;
  }

  .wpcf7-form select {
    width: 100%;
    margin: 5px 0;
  }

  .wpcf7-form input[type="radio"] {
    width: auto;
  }

  .wpcf7-form input[type="submit"] {
    margin: 20px auto;
    padding: 10px 0;
    background: #008c43;
    color: #fff;
    border: 0;
    box-shadow: none;
    font-size: 16px;
    width: 100%;
    display: block;
  }

  .wpcf7-form input[type="submit"]:hover {
    background: #00b155;
    color: #fff;
  }

  .wpcf7-form input[type="submit"]:active {
    background: #00b155;
    box-shadow: none;
    color: #fff;
  }

  .accept_box .wpcf7-not-valid-tip {
    position: absolute;
    bottom: -22px;
    left: -200% !important;
    min-width: 300px !important;
  }

  ul.tag_cloud_with_count .name {
    width: 82%;
  }

  ul.tag_cloud_with_count .hash {
    margin: 0 0 0 0;
    width: 1em;
  }

  ul.tag_cloud {
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  ul.tag_cloud li {
    min-width: 100px;
    flex: inherit;
    margin: 0 5px 5px 0;
    padding: 0;
    word-break: break-all;
  }

  ul.tag_cloud li a {
    width: 100%;
    height: auto;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #3892d0;
    border-radius: 3px;
    color: #3892d0;
    font-size: 12px;
    font-weight: bold;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .post blockquote {
    padding: 20px !important;
    margin: 25px 0;
    border: #cacaca 3px double;
  }

  /*スマホ*/
  .sp_on {
    display: inline !important;
  }

  .pc_on {
    display: none !important;
  }
}
</style>
