<template>
  <div>
    <ScrollTop />
    <footer class="f-footer">
      <div class="footer_menu">
        <div class="logo"> <a :href="`${wpLink}`"><img
              src="@/assets/icons/logo_w.svg"
              width="150"
              height="auto"
              alt=""
            ></a> </div>
        <div class="submenu">
          <div class="box">
            <a :href="`${wpLink}travel/`">
              <h2>旅を知る</h2>
            </a>
            <a :href="`${wpLink}tags/`">目的から探す</a>
            <a :href="`${wpLink}series`">特集から探す</a>
            <a :href="`${wpLink}writer`">ライター 一覧</a>
          </div>
          <div class="box"> <a :href="`${wpLink}movie`">
              <h2>動画で旅する</h2>
            </a> <a @click="facilityListLink()" href="#">
              <h2>宿を探す</h2>
            </a>
            <a :href="`${wpLink}/reservationlist`">
              <h2>オンライン予約</h2>
            </a>
          </div>
          <div class="box">
            <a :href="`${wpLink}company`">会社概要</a>
            <a :href="`${wpLink}contact`">お問い合わせ</a>
          </div>
        </div>
        <div class="icon">
          <div class="sns"><a
              href="https://www.youtube.com/channel/UCZHIVCooPuLAfZXJlDcbXeg"
              target="_blank"
            ><img
                src="@/assets/icons/icon_youtube.svg"
                width="auto"
                height="27"
                alt=""
              ></a><a
              href="https://www.facebook.com/tabinekonotabi/"
              target="_blank"
            ><img
                src="@/assets/icons/icon_facebook.svg"
                width="32"
                height="auto"
                alt=""
              ></a><a
              href="https://twitter.com/yadosagashi"
              target="_blank"
            ><img
                src="@/assets/icons/icon_twitter.svg"
                width="32"
                height="auto"
                alt=""
              ></a><a
              href="https://www.instagram.com/tabinekonotabi/"
              target="_blank"
            ><img
                src="@/assets/icons/icon_instagram.svg"
                width="30"
                height="auto"
                alt=""
              ></a>
          </div>
        </div>
      </div>
      <div class="copylight">© Liberty All Right Reserved.</div>
    </footer>
  </div>
</template>

<script>
import config from "@/config";
export default {
  data() {
    return {
      wpLink: config.WPSITE,
    };
  },
  methods: {
    /**
     *  button: go to facility list page
     *
     */
    facilityListLink() {
      this.$router.push({
        name: "FacilityListView",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.f-footer {
  background: #000;
  padding: 30px 0 0 0;
  color: #fff;
  font-size: 14px;
  min-width: 1300px;
  .logo {
    width: 20%;
    text-align: center;
    margin: 0;
    a {
      margin: 0 auto;
      img {
        max-width: 100%;
      }
    }
  }

  .submenu {
    width: 55%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .box {
      width: 150px;
      h2 {
        font-size: 15px;
        font-weight: bold;
        line-height: 22.5px;
        margin: 0 0 10px 0;
      }
      a {
        font-size: 12px;
        color: #fff;
        display: block;
        line-height: 18px;
        margin: 0 0 5px 0;
      }
    }
  }
  .icon {
    width: 25%;
    text-align: center;
    .sns {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      margin: 0 0 20px 0;
      a {
        margin: 0 0 0 24px;
      }
    }
  }
  .footer_menu {
    width: 1100px;
    margin: 0 auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .copylight {
    margin: 0 auto;
    font-size: 10px;
    padding: 10px;
    line-height: 15px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .f-footer {
    min-width: 100%;
    background: #000;
    padding: 20px 0 0 0 !important;
    color: #fff;
    font-size: 14px;
    min-width: inherit;

    .footer_menu {
      width: auto;
      margin: 0 auto 0 auto;
      padding: 20px 20px 0 20px !important;
      display: flex;
    }

    .logo {
      width: 100%;
      text-align: center;
      margin: 0;
      order: 2;
      a {
        margin: 0 auto;
        width: 30% !important;
        display: block;
      }
    }
    .submenu {
      display: none;
    }
    .icon {
      width: 100%;
      text-align: center;
    }
    .sns {
      display: flex;
      flex-wrap: wrap !important;
      justify-content: center !important;
      align-items: center !important;
      margin: 0 0 40px 0 !important;
      padding: 0 0 30px 0 !important;
      order: 1;
      border-bottom: #cacaca 1px solid;
      a {
        margin: 0 12px;
        width: 10%;
      }
    }
  }
}
</style>

